import { styled } from './styled';

export const StyledTocNav = styled('nav', {
	margin: 'auto',
});

export const StyledTocNavContainer = styled('div', {
	paddingTop: '$36',
	paddingBottom: '$20',
	lg: {
		paddingTop: '$20',
	},
});

export const StyledTocList = styled('ul', {
	listStyle: 'none',
});

export const StyledTocListItem = styled('li', {
	fontFamily: '$sans',
	fontWeight: '$500',
	color: '$mono70',
	fontSize: '$3s',
	marginTop: '$3',
	marginBottom: '$3',
	variants: {
		depth: {
			$0: {
				paddingLeft: '$0',
			},
			$1: {
				paddingLeft: '$2',
			},
			$2: {
				paddingLeft: '$2',
			},
			$3: {
				paddingLeft: '$0',
			},
			$4: {
				paddingLeft: '$0',
			},
		},
	},
});
