import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Mdx from '../ui/mdx';
import {
	StyledMain,
	StyledArticle,
	StyledArticleHeader,
	StyledContainer,
	StyledToc,
	StyledTocScroll,
} from '../ui/layout';
import { Heading, Text } from '../ui/text';

import { Toc } from '../components/toc';

export default props => {
	const { mdx } = props.data;

	return (
		<>
			<StyledMain>
				<StyledArticle>
					<StyledContainer>
						<StyledArticleHeader>
							<Heading size={1}>{mdx.frontmatter.title}</Heading>
							{mdx.frontmatter.description && (
								<Text size="large" color="mono50">
									{mdx.frontmatter.description}
								</Text>
							)}
						</StyledArticleHeader>
						<Mdx>
							<MDXRenderer children={mdx.body} />
						</Mdx>
					</StyledContainer>
				</StyledArticle>
				<StyledToc>
					<StyledTocScroll>
						<Toc path={props.path} table={mdx.tableOfContents} />
					</StyledTocScroll>
				</StyledToc>
			</StyledMain>
		</>
	);
};

export const pageQuery = graphql`
	query($id: String!) {
		mdx(id: { eq: $id }) {
			id
			frontmatter {
				title
				description
			}
			body
			tableOfContents
		}
	}
`;
