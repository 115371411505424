import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

import {
	StyledTocNav,
	StyledTocNavContainer,
	StyledTocList,
	StyledTocListItem,
} from '../ui/toc';

export const Toc = ({ path, table }) => {
	return table.items ? (
		<StyledTocNav>
			{/* <StyledTocHeading>Contents</StyledTocHeading> */}
			<StyledTocNavContainer>
				<NavSection path={path} items={table.items} depth={0} />
			</StyledTocNavContainer>
		</StyledTocNav>
	) : null;
};

const NavSection = ({ path, items, depth }) => {
	return (
		<>
			<StyledTocList>
				{items.map(d => (
					<NavItem key={d.title} item={d} depth={depth} path={path} />
				))}
			</StyledTocList>
		</>
	);
};

const NavItem = ({ path, item, depth }) => {
	return (
		<StyledTocListItem
			key={item.title}
			depth={item.title ? `$${depth}` : '$0'}
		>
			{item.title ? (
				<AnchorLink to={`${path}${item.url}`}>{item.title}</AnchorLink>
			) : null}
			{item.items ? (
				<NavSection
					depth={item.title ? depth + 1 : depth}
					path={path}
					items={item.items}
				/>
			) : null}
		</StyledTocListItem>
	);
};
